<div
  class="dashboard dashboard-center container-detail"
  style="padding: 1rem"
  *ngIf="orderLoaded | async"
>
  <app-alert></app-alert>
  <div class="left-column-detail" *ngIf="Paso1 || Paso2 || Paso4">
    <div class="container Detalle-pedido" *ngIf="Paso1 || Paso2 || Paso4">
      <div class="box">
        <div class="content">
          <div class="label-title">
            <label>Detalle pedido</label>
            <a (click)="toggleAccordion()" *ngIf="isAccordionOpen">
              <mat-icon>expand_less</mat-icon></a
            >
            <a (click)="toggleAccordion()" *ngIf="!isAccordionOpen">
              <mat-icon>expand_more</mat-icon></a
            >
          </div>
          <div
            class="d-md-flex justify-content-between mr-md-4 detail-data"
            *ngIf="isAccordionOpen"
          >
            <div class="c-1">
              <div class="label-row-gray">
                <label class="style-label-title"
                  >{{order.neg=='3100' ? 'Destinatario:' :  'Obra:'}}
                  <span class="style-span-title"
                    >{{ order.workName }}
                  </span></label
                >
              </div>
              <div class="label-row-gray">
                <span class="style-span"
                  >Direccion: {{ order.deliveryAddress }}
                </span>
              </div>
              <br />
            </div>

            <div class="mr-md-5">
              <div class="label-row-gray">
                <label class="style-label-title"
                  >{{order.neg=='3400' ? 'Entrega:' : order.neg=='3100' ? 'N° de entrega:' : 'N° de pedido:'}}<span class="style-span-title">
                    {{ order.orderNumber }}
                  </span></label
                >
              </div>
              <div class="label-row-gray">
                <span class="style-span">{{ order.productDescription }}</span>
              </div>
              <div class="label-row-gray">
                <span class="style-span"
                  >Carga: {{ order.actualQuantityShipped }} {{order.um}}
                </span>
              </div>
              <div class="label-row-gray" *ngIf="order.neg!='3400' && order.neg!='3100'">
                <span class="style-span"
                  >Camion: {{ order.currentTripNumber }}/{{
                    order.loadAmount
                  }}</span
                >
              </div>
              <div class="label-row-gray" *ngIf="order.neg !='3100'">
                <span class="style-span"
                  >{{order.neg=='3400' ? 'Numero de transporte:' :  'N° de sello:'}} {{ order.sealNumber }}
                </span>
              </div>
              <div class="label-row-gray" *ngIf="order.neg !='3100'">
                <span class="style-span">Muestra: {{ order.samples }}</span>
              </div>
              <br />
            </div>
            <div>
              <div class="label-row-gray" *ngIf="order.neg!='3400' && order.neg!='3100'">
                <label class="style-label-title"
                  >Camión anterior: {{ order.truckNumber }}</label
                >
              </div>
              <div class="label-row-gray">
                <span class="style-span">{{ order.driverName }} </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="Paso1 || Paso2" style="text-align: end; margin-top: 0.5rem">
      <app-pedidos-pendiente-visualizarguia
        [isOnlyIcon]="false"
        [rutaPdf]="order.routePdfGuide"
      ></app-pedidos-pendiente-visualizarguia>
      <br />
    </div>

    <div class="container EstadoPedido" *ngIf="isArido">
      <div class="box-estado">
        <div class="content">
          <div class="label-title">
            <label>Estado del camión</label>
          </div>
          <label>
            Puedes marcar el estado del camión en caso de que no se actualice
            automáticamente.
          </label>
          <div class="container-step">
            <ng-container *ngFor="let status of statuses; let i = index">
              <div
                class="status-circle"
                [ngClass]="{ active: i <= currentStatusIndex }"
                (click)="setStatus(i)"
              >
                <div *ngIf="i <= currentStatusIndex" class="inner-circle"></div>
                &nbsp;<span
                  class="status-name"
                  [ngClass]="{ 'active-text': i <= currentStatusIndex }"
                  >{{ status }}
                </span>
              </div>
              <span *ngIf="i < statuses.length - 1" class="status-divider"
                >....</span
              >
            </ng-container>
          </div>
        </div>
        <br />
      </div>
    </div>

  </div>

  <div class="Paso1 step-right" *ngIf="Paso1">

  

    <br class="hide-XL" />
    <div class="buttons-container show-XL" *ngIf="Paso1">
      <button
        type="button"
        class="btn btn-secondary"
        (click)="toRejection()"
      >
        Camión rechazado
      </button>
      <div class="my-2 my-md-0"></div>
      <button
        type="button"
        [ngClass]="{ disabled: currentStatusIndex < 4 }"
        class="btn btn-primary ml-md-4"
        (click)="ContinuarPaso2()"
      >
        Continuar
      </button>
    </div>
  </div>
  <div
    class="step-right"
    *ngIf="!Paso1"
    [ngClass]="{ 'reject-container': Paso5 }"
  >
    <div class="col-12 hide-XL" *ngIf="Paso2"><br /></div>
    <div class="Observaciones-camion paso2" *ngIf="Paso2">
      <ng-template #contenidoModal>
        <div>
          <div class="header-container-modal">
            <label></label>
            <button
              mat-icon-button
              class="close-icon"
              (click)="onCierrenModal()"
            >
              <mat-icon aria-label="Cerrar">close</mat-icon>
            </button>
          </div>

          <div style="text-align: center">
            <img
              src="assets/images/icons/warningmodal.svg"
              class="img-fluid style-warning"
              alt=""
            />
            <h6 class="text-title-modal">¿Deseas confirmar los datos?</h6>
            <p class="text-descripcion-modal">
              Una vez confirmados no será posible cambiar la información.
            </p>
          </div>
          <div class="row size-button">
            <div class="col-6">
              <button type="button" class="btn btn-primary-update">
                Editar
              </button>
            </div>
            <div class="col-6">
              <button
                type="button"
                class="btn btn-primary"
                (click)="confirmarObservacion()"
              >
                Guardar
              </button>
            </div>
          </div>
        </div>
      </ng-template>

      <app-pedidos-pendiente-observaciones
        [order]="order"
        (nextStep)="toStep3()"
        (rejectTruck)="toRejection()"
      ></app-pedidos-pendiente-observaciones>
    </div>

    <div class="Paso3 qr" *ngIf="Paso3">
      <app-pedidos-pendiente-qr
        [order]="order"
        (toNextStep)="toStep4()"
      ></app-pedidos-pendiente-qr>
    </div>

    <div class="Paso4 Firma" *ngIf="Paso4">
      <app-pedidos-pendiente-firma
        [order]="order"
      ></app-pedidos-pendiente-firma>
    </div>

    <div class="Paso5 Rechazo" *ngIf="Paso5">
      <app-rechazo-camion
        [order]="order"
        (returnButton)="backToStep1($event)"
      ></app-rechazo-camion>
    </div>
  </div>
</div>
<div class="buttons-container hide-XL" *ngIf="Paso1">
  <button
    type="button"
    class="btn btn-secondary"
    (click)="toRejection()"
  >
    Camión rechazado
  </button>
  <div class="my-2 my-md-0"></div>
  <button
    type="button"
    [ngClass]="{ disabled: currentStatusIndex < 4 }"
    class="btn btn-primary ml-md-4"
    (click)="ContinuarPaso2()"
  >
    Continuar
  </button>
</div>
